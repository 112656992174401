import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes2";
const NavBar = () => (
<DefaultNavbar
        routes={routes}
        
        sticky
      />
    );

export default NavBar;